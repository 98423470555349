import axios from '@/api/axios';

const getTeam = (id) => axios.get(`/team/${id}`);

const getCompany = (id) => axios.get(`/team/${id}/company`);

const getSpecializations = (id) => axios.get(`/team/${id}/specializations`);

// team members
const getMembers = (id) => axios.get(`/team/${id}/employee`);

const addMember = (id, data) => axios.post(`/team/${id}/employee`, data);

const addMembers = (id, data) => axios.post(`/team/${id}/employee/from_pack`, data);

const deleteMember = (id, employeeId) => axios.delete(`/team/${id}/employee/${employeeId}`);

const changeLeaderAttr = (id, employeeId, leadMark) =>
  axios.post(`/team/${id}/employee/${employeeId}`, leadMark, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

// tests
const getMetadataTests = (id) => axios.get(`/team/${id}/reportResults`);

const getMetadataTests360 = (id) => axios.get(`/team/${id}/test360`);

const getStatisticsTest360 = (id, testId) => axios.get(`/team/${id}/test360/${testId}`);

const getDetailedMetadataTests = (id) => axios.get(`/team/${id}/employeeSpecs`);

const createTests360 = (id, data) => axios.post(`/team/${id}/test360/from_pack`, data);

const getExpertsTest360 = (id, userId, is180) =>
  axios.get(`/team/${id}/user/${userId}/test360/participants${is180 ? `?is_180=${is180}` : ''}`);

const changeTest360 = (id, testId, data) => axios.put(`/team/${id}/test360/${testId}`, data);

const changeStatusTest360 = (id, testing360Id, status) =>
  axios.post(`/team/${id}/test360/${testing360Id}/state/${status}`);

const changePeriodTest360 = (id, testId, data) =>
  axios.put(`/team/${id}/test360/${testId}/time_limit`, data);

// открытие или закрытие тестов 360 для перепрохождения, по умолчанию закрыты
const reEvalLockOfTests360 = (id, data) => axios.post(`/team/${id}/test360/reEvalLock`, data);

// открытие или закрытие тестов 360 для прохождения, по умолчанию открыты
const closeTests360 = (id, data) => axios.post(`/team/${id}/tests360/close`, data);

const setHiddenReports360 = (id, data) => axios.post(`/team/${id}/test360/set_hidden`, data);

const exportTests360 = (id, type = 'pivotExport', dateFrom, dateTo, email, is_async) =>
  axios.get(
    `/team/${id}/test360/${type}?date_start=${dateFrom}&date_end=${dateTo}${
      email ? `&email=${email}` : ''
    }${is_async ? `&is_async=${is_async}` : ''}`,
    {
      responseType: is_async ? 'json' : 'blob',
    }
  );

// roadmaps
const exportRoadmaps360 = (id, dateFrom, dateTo) =>
  axios.get(`/team/${id}/roadmap360/mass_fixed_export?date_start=${dateFrom}&date_end=${dateTo}`, {
    responseType: 'blob',
  });

// reminders about tests
const dryRunRemindersTests360 = (id, data) =>
  axios.post(`/team/${id}/test360/check_box_reminder/dry-run`, data);

const dryRunRemindersSelfTests = (id, data) =>
  axios.post(`/team/${id}/self_tests/check_box_reminder/dry-run`, data);

const dryRunRemindersPersonalTests = (id, data) =>
  axios.post(`/team/${id}/motivation_tests/check_box_reminder/dry-run`, data);

const createRemindersTests360 = (id, data) =>
  axios.post(`/team/${id}/test360/check_box_reminder`, data);

const createRemindersSelfTests = (id, data) =>
  axios.post(`/team/${id}/self_tests/check_box_reminder`, data);

const createRemindersPersonalTests = (id, data) =>
  axios.post(`/team/${id}/motivation_tests/check_box_reminder`, data);

export default {
  getTeam,
  getCompany,
  getSpecializations,

  getMembers,
  addMember,
  addMembers,
  deleteMember,

  changeLeaderAttr,

  getMetadataTests,
  getMetadataTests360,
  getStatisticsTest360,
  getDetailedMetadataTests,
  createTests360,
  getExpertsTest360,
  changeTest360,
  changeStatusTest360,
  changePeriodTest360,
  reEvalLockOfTests360,
  closeTests360,
  setHiddenReports360,
  exportTests360,
  exportRoadmaps360,

  dryRunRemindersTests360,
  dryRunRemindersSelfTests,
  dryRunRemindersPersonalTests,

  createRemindersTests360,
  createRemindersSelfTests,
  createRemindersPersonalTests,
};
