export const routeNames = {
  home: 'home',
  main: {
    dashboard: 'dashboard',
    profile: 'profile',
    userProfile: 'user-profile',
    resume: 'resume',
    testing360: 'testing-360',
    testing360Pulse: 'testing-360-pulse',
    testingSelf: 'testing-self',
    companyFeed: 'company-feed',
    targets: {
      targetsSelf: 'targets-self',
    },
    meetings: {
      meetingsParticipant: 'meetings-participant',
      meetingsParticipantUser: 'meetings-participant-user',
      meetingsSupervisor: 'meetings-supervisor',
      meetingsCreate: 'meetings-create',
      meetingsDetails: 'meetings-details',
    },
    progress: {
      progressSelf360: 'progress-self-360',
      progressNotSelf360: 'progress-not-self-360',
      progressApproval360: 'progress-approval-360',
      progressSelf: 'progress-self',
      progressFeedbacks: 'progress-feedbacks',
      progressPersonal: 'progress-personal',
      progressUserSurveys: 'progress-user-surveys',
      progressUserTests: 'progress-user-tests',
    },
    curator: {
      curatorAdaptation: 'curator-adaptation',
      curatorTargets: 'curator-targets',
    },
    report: {
      report360: 'report-360',
      reportSelf: 'report-self',
      reportPersonal: 'report-personal',
      reportTeam: 'report-team',
    },
    roadmap: {
      roadmap360: 'roadmap-360',
      roadmap360ByTest: 'roadmap-360-by-test',
      roadmapSelf: 'roadmap-self',
    },
    board: {
      boardTasks: 'board-tasks',
      boardAdaptation: 'board-adaptation',
    },
    company: {
      companyFeed: 'company-feed-settings',
      companyStatistic: 'company-statistic',
      companyScore360: 'company-score-360',
      companyScorePersonal: 'company-score-personal',
      companyScoreSelf: 'company-score-self',
      companyRoadmaps: 'company-roadmaps',
      companyEmployees: 'company-employees',
      companyTargets: 'company-targets',
      companySurveys: 'company-surveys',
      companySurveysEdit: 'company-surveys-edit',
      companySurveysEditMain: 'company-surveys-edit-main',
      companySurveysEditQuestions: 'company-surveys-edit-questions',
      companySurveysEditAccess: 'company-surveys-edit-access',
      companySurveysEditMailing: 'company-surveys-edit-mailing',
      companySurveysSummary: 'company-surveys-summary',
      companySurveysDetails: 'company-surveys-details',
      companySurveysAnalyticsStatistic: 'company-surveys-analytic-statistic',
      companySurveysAnalyticsWellbeing: 'company-surveys-analytic-wellbeing',
      companySurveysAnalyticsBurnoutAtWork: 'company-surveys-analytic-burnout-at-work',
      companyFeedbacks: 'company-feedbacks',
      companyFeedbacksMethodologies: 'company-feedbacks-methodologies',
      companyFeedbacksSummary: 'company-feedbacks-summary',
      companyFeedbacksDetails: 'company-feedbacks-details',
      companyFeedbacksUserDetails: 'company-feedbacks-user-details',
      companyTests: 'company-tests',
      companyTestsEdit: 'company-tests-edit',
      companyTestsEditMain: 'company-tests-edit-main',
      companyTestsEditQuestions: 'company-tests-edit-questions',
      companyTestsEditAccess: 'company-tests-edit-access',
      companyTestsEditMailing: 'company-tests-edit-mailing',
      companyTestsEditScoring: 'company-tests-edit-scoring',
      companyResearchmentSurveys: 'company-researchment-surveys',
      companyResearchmentSurveysDetails: 'company-researchment-surveys-details',
      companyResearchmentSurveysSummary: 'company-researchment-surveys-summary',
      companyResearchmentSurveysTemplates: 'company-researchment-surveys-templates',
      companyResearchmentSurveysEdit: 'company-researchment-surveys-edit',
      companyResearchmentSurveysEditMain: 'company-researchment-surveys-edit-main',
      companyResearchmentSurveysEditQuestions: 'company-researchment-surveys-edit-questions',
      companyResearchmentTestsEditMain: 'company-researchment-tests-edit-main',
      companyResearchmentTestsEditQuestions: 'company-researchment-tests-edit-questions',
      companyResearchmentTestsEditScoring: 'company-researchment-tests-edit-scoring',
      companyResearchmentTest: 'company-researchment-tests',
      companyResearchmentTestTemplates: 'company-researchment-tests-templates',
      companyAdaptation: 'company-adaptation',
      companyAdaptationSettings: 'company-adaptation-settings',
      companyStructure: 'company-structure',
      companyProfile: 'company-profile',
      companySettings: 'company-settings',
      companyDemoPersonalTests: 'company-demo-personal-tests',
      companyMeetings: 'company-meetings',
      companyMeetingsCreate: 'company-meetings-create',
      companyMeetingsDetails: 'company-meetings-details',
    },
    team: {
      teamScore: 'team-score',
      teamRoles: 'team-roles',
      teamMembers: 'team-members',
    },
    administration: {
      users: 'users',
    },
    admin: {
      skills: 'admin-skills',
      specializations: 'admin-specializations',
    },
    documents: 'documents',
    survey: 'survey',
    feedback: 'feedback',
    notFound: '404',
  },
};

export const companyBreadcrumbsNames = [
  routeNames.main.company.companySurveysSummary,
  routeNames.main.company.companySurveysDetails,
  routeNames.main.company.companySurveysEdit,
  routeNames.main.company.companySurveysEditMain,
  routeNames.main.company.companyTestsEdit,
  routeNames.main.company.companyTestsEditMain,
  routeNames.main.company.companySurveysEditAccess,
  routeNames.main.company.companySurveysEditQuestions,
  routeNames.main.company.companySurveysEditMailing,
  routeNames.main.company.companyTestsEditQuestions,
  routeNames.main.company.companyTestsEditAccess,
  routeNames.main.company.companyTestsEditMailing,
  routeNames.main.company.companyTestsEditScoring,
  routeNames.main.company.companyResearchmentSurveys,
  routeNames.main.company.companyResearchmentSurveysEditMain,
  routeNames.main.company.companyResearchmentSurveysEditQuestions,
  routeNames.main.company.companyResearchmentTestsEditMain,
  routeNames.main.company.companyResearchmentTestsEditQuestions,
  routeNames.main.company.companyResearchmentTestsEditScoring,
  routeNames.main.company.companyResearchmentSurveysTemplates,
  routeNames.main.company.companyResearchmentTest,
  routeNames.main.company.companyResearchmentTestTemplates,
  routeNames.main.company.companyResearchmentSurveysDetails,
  routeNames.main.company.companyResearchmentSurveysSummary,
  routeNames.main.company.companyAdaptationSettings,
  routeNames.main.company.companyFeedbacksMethodologies,
  routeNames.main.company.companyFeedbacksSummary,
  routeNames.main.company.companyFeedbacksDetails,
  routeNames.main.company.companyFeedbacksUserDetails,
  routeNames.main.company.companyDemoPersonalTests,
  routeNames.main.company.companyMeetingsCreate,
  routeNames.main.company.companyMeetingsDetails,
];

export const companyBreadcrumbsSurveysNames = [
  routeNames.main.company.companySurveysEdit,
  routeNames.main.company.companySurveysEditMain,
  routeNames.main.company.companySurveysEditAccess,
  routeNames.main.company.companySurveysEditQuestions,
  routeNames.main.company.companySurveysEditMailing,
];

export const companyBreadcrumbsTestsNames = [
  routeNames.main.company.companyTestsEdit,
  routeNames.main.company.companyTestsEditMain,
  routeNames.main.company.companyTestsEditAccess,
  routeNames.main.company.companyTestsEditQuestions,
  routeNames.main.company.companyTestsEditMailing,
  routeNames.main.company.companyTestsEditScoring,
];

export const teamBreadcrumbsNames = [routeNames.main.team.teamRoles];

export const allowedUnAuthNames = [routeNames.main.survey, routeNames.main.feedback];
