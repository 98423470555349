import axios from '@/api/axios';

const getLicenses = () => axios.get('/license');

const assignLicenses = (data) => axios.post('/license/assign', data);

const massAssignLicenses = (data) => axios.post('/license/assign/from_pack', data);

export default {
  getLicenses,
  assignLicenses,
  massAssignLicenses,
};
