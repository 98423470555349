export const mutationTypes = {
  getCompaniesStart: '[company] get all companies start',
  getCompaniesSuccess: '[company] get all companies success',
  getCompaniesFailure: '[company] get all companies failure',

  getCompanyStart: '[company] get company start',
  getCompanySuccess: '[company] get company success',
  getCompanyFailure: '[company] get company failure',

  getAllCompanyListStart: '[company] get company list start',
  getAllCompanyListSuccess: '[company] get company list success',
  getAllCompanyListFailure: '[company] get company list failure',

  getCompanyStatisticStart: '[company] get company statistic start',
  getCompanyStatisticSuccess: '[company] get company statistic success',
  getCompanyStatisticFailure: '[company] get company statistic failure',

  getCurrentSpecializationsStart: '[company] get current specializations start',
  getCurrentSpecializationsSuccess: '[company] get current specializations success',
  getCurrentSpecializationsFailure: '[company] get current specializations failure',

  getSpecializationsStart: '[company] get specializations start',
  getSpecializationsSuccess: '[company] get specializations success',
  getSpecializationsFailure: '[company] get specializations failure',

  getCurrentSettingsStart: '[company] get current settings start',
  getCurrentSettingsSuccess: '[company] get current settings success',
  getCurrentSettingsFailure: '[company] get current settings failure',

  getSettingsStart: '[company] get settings start',
  getSettingsSuccess: '[company] get settings success',
  getSettingsFailure: '[company] get settings failure',

  getRecordTypesStart: '[company] get record types start',
  getRecordTypesSuccess: '[company] get record types success',
  getRecordTypesFailure: '[company] get record types failure',

  changeSettingsStart: '[company] change settings start',
  changeSettingsSuccess: '[company] change settings success',
  changeSettingsFailure: '[company] change settings failure',

  hideSelfTestingStart: '[company] hide self-testing start',
  hideSelfTestingSuccess: '[company] hide self-testing success',
  hideSelfTestingFailure: '[company] hide self-testing failure',

  hideFeedStart: '[company] hide feed start',
  hideFeedSuccess: '[company] hide feed success',
  hideFeedFailure: '[company] hide feed failure',

  changeFlagsStart: '[company] change flags start',
  changeFlagsSuccess: '[company] change flags success',
  changeFlagsFailure: '[company] change flags failure',

  changeCompanyStart: '[company] change metadata start',
  changeCompanySuccess: '[company] change metadata success',
  changeCompanyFailure: '[company] change metadata failure',

  changeLogoStart: '[company] change logo start',
  changeLogoSuccess: '[company] change logo success',
  changeLogoFailure: '[company] change logo failure',

  changeServicesStart: '[company] change services start',
  changeServicesSuccess: '[company] change services success',
  changeServicesFailure: '[company] change services failure',

  getPortfolioStart: '[company] get portfolio start',
  getPortfolioSuccess: '[company] get portfolio success',
  getPortfolioFailure: '[company] get portfolio failure',

  createPortfolioStart: '[company] create portfolio start',
  createPortfolioSuccess: '[company] create portfolio success',
  createPortfolioFailure: '[company] create portfolio failure',

  deletePortfolioStart: '[company] delete portfolio start',
  deletePortfolioSuccess: '[company] delete portfolio success',
  deletePortfolioFailure: '[company] delete portfolio failure',

  getEmployeesStart: '[company] get employees start',
  getEmployeesSuccess: '[company] get employees success',
  getEmployeesFailure: '[company] get employees failure',

  changeBanEmployeesStart: '[company] change ban employees start',
  changeBanEmployeesSuccess: '[company] change ban employees success',
  changeBanEmployeesFailure: '[company] change ban employees failure',

  importEmployeesStart: '[company] import employees start',
  importEmployeesSuccess: '[company] import employees success',
  importEmployeesFailure: '[company] import employees failure',

  getStatusesImportEmployeesStart: '[company] get statuses import employees start',
  getStatusesImportEmployeesSuccess: '[company] get statuses import employees success',
  getStatusesImportEmployeesFailure: '[company] get statuses import employees failure',

  inviteEmployeeStart: '[company] invite employee start',
  inviteEmployeeSuccess: '[company] invite employee success',
  inviteEmployeeFailure: '[company] invite employee failure',

  inviteEmailEmployeeStart: '[company] invite employee by email start',
  inviteEmailEmployeeSuccess: '[company] invite employee by email success',
  inviteEmailEmployeeFailure: '[company] invite employee by email failure',

  cancelInvitationEmployeeStart: '[company] cancel invite employee start',
  cancelInvitationEmployeeSuccess: '[company] cancel invite employee success',
  cancelInvitationEmployeeFailure: '[company] cancel invite employee failure',

  changeEmployeeStatusStart: '[company] change employee status start',
  changeEmployeeStatusSuccess: '[company] change employee status success',
  changeEmployeeStatusFailure: '[company] change employee status failure',

  changeOrganizersStart: '[company] change organizers start',
  changeOrganizersSuccess: '[company] change organizers success',
  changeOrganizersFailure: '[company] change organizers failure',

  getManagersStart: '[company] get managers start',
  getManagersSuccess: '[company] get managers success',
  getManagersFailure: '[company] get managers failure',

  changeManagersStart: '[company] change managers start',
  changeManagersSuccess: '[company] change managers success',
  changeManagersFailure: '[company] change managers failure',

  deleteManagerStart: '[company] delete manager start',
  deleteManagerSuccess: '[company] delete manager success',
  deleteManagerFailure: '[company] delete manager failure',

  getTests360Start: '[company] get tests 360 start',
  getTests360Success: '[company] get tests 360 success',
  getTests360Failure: '[company] get tests 360 failure',

  getSelfTestsStart: '[company] get self tests start',
  getSelfTestsSuccess: '[company] get self tests success',
  getSelfTestsFailure: '[company] get self tests failure',

  getPersonalTestsStart: '[company] get personal tests start',
  getPersonalTestsSuccess: '[company] get personal tests success',
  getPersonalTestsFailure: '[company] get personal tests failure',

  getEnabledPersonalTestsStart: '[company] get enabled personal tests start',
  getEnabledPersonalTestsSuccess: '[company] get enabled personal tests success',
  getEnabledPersonalTestsFailure: '[company] get enabled personal tests failure',

  reEvalLockOfTests360Start: '[company] re-evaluation lock of tests 360 start',
  reEvalLockOfTests360Success: '[company] re-evaluation lock of tests 360 success',
  reEvalLockOfTests360Failure: '[company] re-evaluation lock of tests 360 failure',

  closeTests360Start: '[company] close tests 360 start',
  closeTests360Success: '[company] close tests 360 success',
  closeTests360Failure: '[company] close tests 360 failure',

  setHiddenReports360Start: '[company] set hidden reports 360 start',
  setHiddenReports360Success: '[company] set hidden reports 360 success',
  setHiddenReports360Failure: '[company] set hidden reports 360 failure',

  exportTests360Start: '[company] export tests 360 start',
  exportTests360Success: '[company] export tests 360 success',
  exportTests360Failure: '[company] export tests 360 failure',

  createTests360Start: '[company] create tests 360 start',
  createTests360Success: '[company] create tests 360 success',
  createTests360Failure: '[company] create tests 360 failure',

  deleteTests360Start: '[company] delete tests 360 start',
  deleteTests360Success: '[company] delete tests 360 success',
  deleteTests360Failure: '[company] delete tests 360 failure',

  dryRunRemindersTests360Start: '[company] dry run reminders about tests 360 start',
  dryRunRemindersTests360Success: '[company] dry run reminders about tests 360 success',
  dryRunRemindersTests360Failure: '[company] dry run reminders about tests 360 failure',

  dryRunRemindersSelfTestsStart: '[company] dry run reminders about self tests start',
  dryRunRemindersSelfTestsSuccess: '[company] dry run reminders about self tests success',
  dryRunRemindersSelfTestsFailure: '[company] dry run reminders about self tests failure',

  dryRunRemindersPersonalTestsStart: '[company] dry run reminders about personal tests start',
  dryRunRemindersPersonalTestsSuccess: '[company] dry run reminders about personal tests success',
  dryRunRemindersPersonalTestsFailure: '[company] dry run reminders about personal tests failure',

  createRemindersTests360Start: '[company] create reminders about tests 360 start',
  createRemindersTests360Success: '[company] create reminders about tests 360 success',
  createRemindersTests360Failure: '[company] create reminders about tests 360 failure',

  createRemindersSelfTestsStart: '[company] create reminders about self tests start',
  createRemindersSelfTestsSuccess: '[company] create reminders about self tests success',
  createRemindersSelfTestsFailure: '[company] create reminders about self tests failure',

  createRemindersPersonalTestsStart: '[company] create reminders about personal tests start',
  createRemindersPersonalTestsSuccess: '[company] create reminders about personal tests success',
  createRemindersPersonalTestsFailure: '[company] create reminders about personal tests failure',

  getTeamsStart: '[company] get teams start',
  getTeamsClear: '[company] get teams clear',
  getTeamsSuccess: '[company] get teams success',
  getTeamsFailure: '[company] get teams failure',

  getAllTeamsStart: '[company] get all teams start',
  getAllTeamsSuccess: '[company] get all teams success',
  getAllTeamsFailure: '[company] get all teams failure',

  getManagedTeamsStart: '[company] get managed teams start',
  getManagedTeamsSuccess: '[company] get managed teams success',
  getManagedTeamsFailure: '[company] get managed teams failure',

  getTeamsTreeStart: '[company] get tree of teams start',
  getTeamsTreeSuccess: '[company] get tree of teams success',
  getTeamsTreeFailure: '[company] get tree of teams failure',

  checkTeamTests360Start: '[company] check team tests 360 start',
  checkTeamTests360Success: '[company] check team tests 360 success',
  checkTeamTests360Failure: '[company] check team tests 360 failure',

  createTeamStart: '[company] create team start',
  createTeamSuccess: '[company] create team success',
  createTeamFailure: '[company] create team failure',

  changeTeamStart: '[company] change team start',
  changeTeamSuccess: '[company] change team success',
  changeTeamFailure: '[company] change team failure',

  deleteTeamStart: '[company] delete team start',
  deleteTeamSuccess: '[company] delete team success',
  deleteTeamFailure: '[company] delete team failure',

  getTeamGroupsFlatStart: '[company] get team groups flat start',
  getTeamGroupsFlatSuccess: '[company] get team groups flat success',
  getTeamGroupsFlatFailure: '[company] get team groups flat failure',

  getTeamGroupsStart: '[company] get team groups start',
  getTeamGroupsSuccess: '[company] get team groups success',
  getTeamGroupsFailure: '[company] get team groups failure',

  getTeamGroupStart: '[company] get team group start',
  getTeamGroupSuccess: '[company] get team group success',
  getTeamGroupFailure: '[company] get team group failure',

  createTeamGroupStart: '[company] create team group start',
  createTeamGroupSuccess: '[company] create team group success',
  createTeamGroupFailure: '[company] create team group failure',

  changeTeamGroupStart: '[company] change team group start',
  changeTeamGroupSuccess: '[company] change team group success',
  changeTeamGroupFailure: '[company] change team group failure',

  changeAllTeamGroupStart: '[company] change all team group start',
  changeAllTeamGroupSuccess: '[company] change all team group success',
  changeAllTeamGroupFailure: '[company] change all team group failure',

  deleteTeamGroupStart: '[company] delete team group start',
  deleteTeamGroupSuccess: '[company] delete team group success',
  deleteTeamGroupFailure: '[company] delete team group failure',

  exportAdaptationStart: '[company] export adaptation start',
  exportAdaptationSuccess: '[company] export adaptation success',
  exportAdaptationFailure: '[company] export adaptation failure',

  exportRoadmaps360Start: '[company] export roadmaps 360 start',
  exportRoadmaps360Success: '[company] export roadmaps 360 success',
  exportRoadmaps360Failure: '[company] export roadmaps 360 failure',

  getLinksStart: '[company] get links start',
  getLinksSuccess: '[company] get links success',
  getLinksFailure: '[company] get links failure',

  createLinkStart: '[company] create link start',
  createLinkSuccess: '[company] create link success',
  createLinkFailure: '[company] create link failure',

  changeLinkStart: '[company] change link start',
  changeLinkSuccess: '[company] change link success',
  changeLinkFailure: '[company] change link failure',

  deleteLinkStart: '[company] delete link start',
  deleteLinkSuccess: '[company] delete link success',
  deleteLinkFailure: '[company] delete link failure',

  switchAuthEmailsStart: '[company] switch auth emails start',
  switchAuthEmailsSuccess: '[company] switch auth emails success',
  switchAuthEmailsFailure: '[company] switch auth emails failure',

  getRoadmapsStart: '[company] get roadmaps start',
  getRoadmapsSuccess: '[company] get roadmaps success',
  getRoadmapsFailure: '[company] get roadmaps failure',

  getTargetsStart: '[company] get targets start',
  getTargetsSuccess: '[company] get targets success',
  getTargetsFailure: '[company] get targets failure',

  getCuratorsByUserStart: '[company] get curators by user start',
  getCuratorsByUserSuccess: '[company] get curators by user success',
  getCuratorsByUserFailure: '[company] get curators by user failure',

  exportTargetsStart: '[company] export targets start',
  exportTargetsSuccess: '[company] export targets success',
  exportTargetsFailure: '[company] export targets failure',

  startReviewTargetsStart: '[company] start review targets start',
  startReviewTargetsSuccess: '[company] start review targets success',
  startReviewTargetsFailure: '[company] start review targets failure',

  getSubordinateEmployeesStart: '[company] get subordinate employees start',
  getSubordinateEmployeesSuccess: '[company] get subordinate employees success',
  getSubordinateEmployeesFailure: '[company] get subordinate employees failure',
};

const mutations = {
  [mutationTypes.getCompaniesStart](state) {
    state.isLoading = true;
    state.companies = null;
  },
  [mutationTypes.getCompaniesSuccess](state, payload) {
    state.isLoading = false;
    state.companies = payload;
  },
  [mutationTypes.getCompaniesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCompanyStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCompanySuccess](state, payload) {
    state.isLoading = false;
    state.metadata = payload;
  },
  [mutationTypes.getCompanyFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getAllCompanyListStart](state) {
    state.companyListLoading = true;
    state.allCompanyList = [];
  },
  [mutationTypes.getAllCompanyListSuccess](state, payload) {
    state.companyListLoading = false;
    state.allCompanyList = payload;
  },
  [mutationTypes.getAllCompanyListFailure](state) {
    state.companyListLoading = false;
  },

  [mutationTypes.getCompanyStatisticStart](state) {
    state.isLoading = true;
    state.license = [];
    state.adaptation = [];
    state.tests360 = [];
    state.personal = [];
    state.targets = null;
  },
  [mutationTypes.getCompanyStatisticSuccess](state, {statisticType, payload}) {
    state.isLoading = false;
    if (statisticType === 'all') {
      Object.keys(payload).forEach((key) => (state[key] = payload[key]));
    } else {
      state[statisticType] = payload;
    }
  },
  [mutationTypes.getCompanyStatisticFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCurrentSpecializationsStart](state) {
    state.isLoading = true;
    state.currentSpecializations = null;
  },
  [mutationTypes.getCurrentSpecializationsSuccess](state, payload) {
    state.isLoading = false;
    state.currentSpecializations = payload;
  },
  [mutationTypes.getCurrentSpecializationsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSpecializationsStart](state) {
    state.isLoading = true;
    state.specializations = null;
  },
  [mutationTypes.getSpecializationsSuccess](state, payload) {
    state.isLoading = false;
    state.specializations = payload;
  },
  [mutationTypes.getSpecializationsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCurrentSettingsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCurrentSettingsSuccess](state, payload) {
    state.isLoading = false;
    state.currentSettings = payload;
  },
  [mutationTypes.getCurrentSettingsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSettingsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getSettingsSuccess](state, payload) {
    state.isLoading = false;
    state.settings = payload;
  },
  [mutationTypes.getSettingsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getRecordTypesStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getRecordTypesSuccess](state, payload) {
    state.isLoading = false;
    state.recordTypes = payload;
  },
  [mutationTypes.getRecordTypesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeSettingsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeSettingsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeSettingsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.hideSelfTestingStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.hideSelfTestingSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.hideSelfTestingFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.hideFeedStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.hideFeedSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.hideFeedFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeFlagsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeFlagsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeFlagsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeCompanyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeCompanySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeCompanyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeLogoStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeLogoSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeLogoFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeServicesStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeServicesSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeServicesFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getPortfolioStart](state) {
    state.isLoading = true;
    state.portfolio = null;
  },
  [mutationTypes.getPortfolioSuccess](state, payload) {
    state.isLoading = false;
    state.portfolio = payload;
  },
  [mutationTypes.getPortfolioFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createPortfolioStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createPortfolioSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createPortfolioFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deletePortfolioStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deletePortfolioSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deletePortfolioFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getEmployeesStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getEmployeesSuccess](state, payload) {
    state.isLoading = false;
    state.employees = payload;
  },
  [mutationTypes.getEmployeesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeBanEmployeesStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeBanEmployeesSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeBanEmployeesFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.importEmployeesStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.importEmployeesSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.importEmployeesFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getStatusesImportEmployeesStart]() {},
  [mutationTypes.getStatusesImportEmployeesSuccess](state, payload) {
    state.statusesImportEmployees = payload;
  },
  [mutationTypes.getStatusesImportEmployeesFailure]() {},

  [mutationTypes.inviteEmployeeStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.inviteEmployeeSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.inviteEmployeeFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.inviteEmailEmployeeStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.inviteEmailEmployeeSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.inviteEmailEmployeeFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.cancelInvitationEmployeeStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.cancelInvitationEmployeeSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.cancelInvitationEmployeeFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.changeEmployeeStatusStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.changeEmployeeStatusSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeEmployeeStatusFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.changeOrganizersStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeOrganizersSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeOrganizersFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getManagersStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getManagersSuccess](state, payload) {
    state.isLoading = false;
    state.managers = payload;
  },
  [mutationTypes.getManagersFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeManagersStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeManagersSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeManagersFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteManagerStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteManagerSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteManagerFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getTests360Start](state) {
    state.isLoading = true;
    state.metadataTests360 = null;
  },
  [mutationTypes.getTests360Success](state, payload) {
    state.isLoading = false;
    state.metadataTests360 = payload;
  },
  [mutationTypes.getTests360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSelfTestsStart](state) {
    state.isLoading = true;
    state.metadataSelfTests = null;
  },
  [mutationTypes.getSelfTestsSuccess](state, payload) {
    state.isLoading = false;
    state.metadataSelfTests = payload;
  },
  [mutationTypes.getSelfTestsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getPersonalTestsStart](state) {
    state.isLoading = true;
    state.metadataPersonalTests = null;
  },
  [mutationTypes.getPersonalTestsSuccess](state, payload) {
    state.isLoading = false;
    state.metadataPersonalTests = payload;
  },
  [mutationTypes.getPersonalTestsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getEnabledPersonalTestsStart](state) {
    state.isLoading = true;
    state.enabledPersonalTests = null;
  },
  [mutationTypes.getEnabledPersonalTestsSuccess](state, payload) {
    state.isLoading = false;
    state.enabledPersonalTests = payload;
  },
  [mutationTypes.getEnabledPersonalTestsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.reEvalLockOfTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.reEvalLockOfTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.reEvalLockOfTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.closeTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.closeTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.closeTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.setHiddenReports360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.setHiddenReports360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.setHiddenReports360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.exportTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.dryRunRemindersTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.dryRunRemindersTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.dryRunRemindersTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.dryRunRemindersSelfTestsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.dryRunRemindersSelfTestsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.dryRunRemindersSelfTestsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.dryRunRemindersPersonalTestsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.dryRunRemindersPersonalTestsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.dryRunRemindersPersonalTestsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createRemindersTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createRemindersTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createRemindersTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createRemindersSelfTestsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createRemindersSelfTestsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createRemindersSelfTestsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createRemindersPersonalTestsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createRemindersPersonalTestsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createRemindersPersonalTestsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getTeamsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getTeamsClear](state) {
    state.teams = null;
  },
  [mutationTypes.getTeamsSuccess](state, payload) {
    state.isLoading = false;
    state.teams = payload;
  },
  [mutationTypes.getTeamsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getAllTeamsStart](state) {
    state.isLoading = true;
    state.allTeams = null;
  },
  [mutationTypes.getAllTeamsSuccess](state, payload) {
    state.isLoading = false;
    state.allTeams = payload;
  },
  [mutationTypes.getAllTeamsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getManagedTeamsStart](state) {
    state.isLoading = true;
    state.managedTeams = null;
  },
  [mutationTypes.getManagedTeamsSuccess](state, payload) {
    state.isLoading = false;
    state.managedTeams = payload;
  },
  [mutationTypes.getManagedTeamsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTeamsTreeStart](state) {
    state.isLoading = true;
    state.teamsTree = null;
  },
  [mutationTypes.getTeamsTreeSuccess](state, payload) {
    state.isLoading = false;
    state.teamsTree = payload;
  },
  [mutationTypes.getTeamsTreeFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.checkTeamTests360Start](state) {
    state.checkTeamTests360Loading = true;
  },
  [mutationTypes.checkTeamTests360Success](state) {
    state.checkTeamTests360Loading = false;
  },
  [mutationTypes.checkTeamTests360Failure](state) {
    state.checkTeamTests360Loading = false;
  },

  [mutationTypes.createTeamStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createTeamSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createTeamFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeTeamStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeTeamSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeTeamFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteTeamStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteTeamSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteTeamFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getTeamGroupsFlatStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getTeamGroupsFlatSuccess](state, payload) {
    state.isLoading = false;
    state.teamGroupsFlat = payload;
  },
  [mutationTypes.getTeamGroupsFlatFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTeamGroupsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getTeamGroupsSuccess](state, payload) {
    state.isLoading = false;
    state.teamGroups = payload;
  },
  [mutationTypes.getTeamGroupsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTeamGroupStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getTeamGroupSuccess](state, payload) {
    state.isLoading = false;
    state.teamGroup = payload;
  },
  [mutationTypes.getTeamGroupFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createTeamGroupStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createTeamGroupSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createTeamGroupFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeTeamGroupStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeTeamGroupSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeTeamGroupFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeAllTeamGroupStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeAllTeamGroupSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeAllTeamGroupFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteTeamGroupStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteTeamGroupSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteTeamGroupFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.exportAdaptationStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportAdaptationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportAdaptationFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.exportRoadmaps360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportRoadmaps360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportRoadmaps360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getLinksStart](state) {
    state.isLoading = true;
    state.links = null;
  },
  [mutationTypes.getLinksSuccess](state, payload) {
    state.isLoading = false;
    state.links = payload;
  },
  [mutationTypes.getLinksFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createLinkStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createLinkSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createLinkFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeLinkStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeLinkSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeLinkFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteLinkStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteLinkSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteLinkFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.switchAuthEmailsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.switchAuthEmailsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.switchAuthEmailsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getRoadmapsStart](state) {
    state.isLoading = true;
    state.roadmaps = null;
  },
  [mutationTypes.getRoadmapsSuccess](state, payload) {
    state.isLoading = false;
    state.roadmaps = payload;
  },
  [mutationTypes.getRoadmapsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTargetsStart](state) {
    state.isLoading = true;
    state.targets = null;
  },
  [mutationTypes.getTargetsSuccess](state, payload) {
    state.isLoading = false;
    state.targets = payload;
  },
  [mutationTypes.getTargetsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCuratorsByUserStart](state) {
    state.isLoading = true;
    state.curators = null;
  },
  [mutationTypes.getCuratorsByUserSuccess](state, payload) {
    state.isLoading = false;
    state.curators = payload;
  },
  [mutationTypes.getCuratorsByUserFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.exportTargetsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportTargetsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportTargetsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.startReviewTargetsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.startReviewTargetsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.startReviewTargetsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getSubordinateEmployeesStart](state) {
    state.isLoading = true;
    state.subordinateEmployees = null;
  },
  [mutationTypes.getSubordinateEmployeesSuccess](state, payload) {
    state.isLoading = false;
    state.subordinateEmployees = payload;
  },
  [mutationTypes.getSubordinateEmployeesFailure](state) {
    state.isLoading = false;
  },
};

export default mutations;
