export const mutationTypes = {
  getReport360Start: '[reports] get report 360 start',
  getReport360Success: '[reports] get report 360 success',
  getReport360Failure: '[reports] get report 360 failure',

  getReportPersonalStart: '[reports] get report personal start',
  getReportPersonalSuccess: '[reports] get report personal success',
  getReportPersonalFailure: '[reports] get report personal failure',

  getReportSelfStart: '[reports] get report self start',
  getReportSelfSuccess: '[reports] get report self success',
  getReportSelfFailure: '[reports] get report self failure',

  getReportSelfVersionsStart: '[reports] get report self versions start',
  getReportSelfVersionsSuccess: '[reports] get report self versions success',
  getReportSelfVersionsFailure: '[reports] get report self versions failure',

  getReportTeamStart: '[reports] get report team start',
  getReportTeamSuccess: '[reports] get report team success',
  getReportTeamFailure: '[reports] get report team failure',

  getReportTeamSpecsStart: '[reports] get report team specs start',
  getReportTeamSpecsSuccess: '[reports] get report team specs success',
  getReportTeamSpecsFailure: '[reports] get report team specs failure',

  getReportTeamPersonalStart: '[reports] get report team personal start',
  getReportTeamPersonalSuccess: '[reports] get report team personal success',
  getReportTeamPersonalFailure: '[reports] get report team personal failure',

  getReportParamsStart: '[reports] get report params start',
  getReportParamsSuccess: '[reports] get report params success',
  getReportParamsFailure: '[reports] get report params failure',

  setReportParamsStart: '[reports] set report params start',
  setReportParamsSuccess: '[reports] set report params success',
  setReportParamsFailure: '[reports] set report params failure',

  getReportLinkStart: '[reports] get report link start',
  getReportLinkSuccess: '[reports] get report link success',
  getReportLinkFailure: '[reports] get report link failure',

  uploadDiagramStart: '[reports] upload diagram start',
  uploadDiagramSuccess: '[reports] upload diagram success',
  uploadDiagramFailure: '[reports] upload diagram failure',
};

const mutations = {
  [mutationTypes.getReport360Start](state) {
    state.isLoading = true;
    state.errors = null;
    state.report360 = null;
    state.reportLink = null;
  },
  [mutationTypes.getReport360Success](state, payload) {
    state.isLoading = false;
    state.report360 = payload;
  },
  [mutationTypes.getReport360Failure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getReportPersonalStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.reportPersonal = null;
    state.reportLink = null;
  },
  [mutationTypes.getReportPersonalSuccess](state, payload) {
    state.isLoading = false;
    state.reportPersonal = payload;
  },
  [mutationTypes.getReportPersonalFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getReportSelfStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.reportSelf = null;
    state.reportLink = null;
  },
  [mutationTypes.getReportSelfSuccess](state, payload) {
    state.isLoading = false;
    state.reportSelf = payload;
  },
  [mutationTypes.getReportSelfFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getReportSelfVersionsStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.reportSelfVersions = null;
  },
  [mutationTypes.getReportSelfVersionsSuccess](state, payload) {
    state.isLoading = false;
    state.reportSelfVersions = payload;
  },
  [mutationTypes.getReportSelfVersionsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getReportTeamStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.reportTeam = null;
  },
  [mutationTypes.getReportTeamSuccess](state, payload) {
    state.isLoading = false;
    state.reportTeam = payload;
  },
  [mutationTypes.getReportTeamFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getReportTeamSpecsStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.reportTeamSpecs = null;
  },
  [mutationTypes.getReportTeamSpecsSuccess](state, payload) {
    state.isLoading = false;
    state.reportTeamSpecs = payload;
  },
  [mutationTypes.getReportTeamSpecsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getReportTeamPersonalStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.teamsPersonal = null;
  },
  [mutationTypes.getReportTeamPersonalSuccess](state, payload) {
    state.isLoading = false;
    state.teamsPersonal = payload;
  },
  [mutationTypes.getReportTeamPersonalFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getReportParamsStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.reportParams = null;
  },
  [mutationTypes.getReportParamsSuccess](state, payload) {
    state.isLoading = false;
    state.reportParams = payload;
  },
  [mutationTypes.getReportParamsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.setReportParamsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.setReportParamsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.setReportParamsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getReportLinkStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.reportLink = null;
  },
  [mutationTypes.getReportLinkSuccess](state, payload) {
    state.isLoading = false;
    state.reportLink = payload;
  },
  [mutationTypes.getReportLinkFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.uploadDiagramStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.uploadDiagramSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.uploadDiagramFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
