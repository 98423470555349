import testingApi from '@/api/testing';
import {mutationTypes} from '@/store/modules/testing/mutations';

export const actionsTypes = {
  getMetadataSelfTests: '[testing] get metadata self tests',
  getMetadataTest360: '[testing] get metadata test 360',
  getMetadataBySpec: '[testing] get metadata test by spec',

  getHistoryPulseTest360: '[testing] get history pulse test 360',
  getUserTest360: '[testing] get user test 360',
  getCommentsTest360: '[testing] get comments test 360',
  getApproversTest360: '[testing] get approvers test 360',
  getDescriptionTest360: '[testing] get description test 360',
  getQuestions: '[testing] get questions',

  getMetadataPersonalTests: '[testing] get metadata personal tests',
  getUrlPersonalTest: '[testing] get url for personal test',
  getVariantsPersonalReport: '[testing] get variants personal report',
  exportPersonalReport: '[testing] export personal report',

  getUserSurveys: '[testing] get user surveys',
  getUserTests: '[testing] get user tests',
  getUserFeedbacks: '[testing] get user feedbacks',

  saveAnswer: '[testing] save answer',
  resetAnswers: '[testing] reset answers',
  saveResults: '[testing] save results',
  createReport: '[testing] create report',
  approveTest360: '[testing] approve test 360',
  setDescriptionTest360: '[testing] set description test 360',
};

const actions = {
  [actionsTypes.getMetadataSelfTests](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMetadataSelfTestsStart);
      testingApi
        .getMetadataSelfTests(userId)
        .then((response) => {
          context.commit(mutationTypes.getMetadataSelfTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMetadataSelfTestsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getMetadataTest360](context, test360Id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMetadataTest360Start);
      testingApi
        .getMetadataTest360(test360Id)
        .then((response) => {
          context.commit(mutationTypes.getMetadataTest360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMetadataTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getMetadataBySpec](context, {userId, specId, test360Id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMetadataBySpecStart);
      testingApi
        .getMetadataBySpec(userId, specId, test360Id)
        .then((response) => {
          context.commit(mutationTypes.getMetadataBySpecSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMetadataBySpecFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getHistoryPulseTest360](context, test360Id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getHistoryPulseTest360Start);
      testingApi
        .getHistoryPulseTest360(test360Id)
        .then((response) => {
          context.commit(mutationTypes.getHistoryPulseTest360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getHistoryPulseTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getUserTest360](context, test360Id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserTest360Start);
      testingApi
        .getUserTest360(test360Id)
        .then((response) => {
          context.commit(mutationTypes.getUserTest360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getCommentsTest360](context, test360Id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCommentsTest360Start);
      testingApi
        .getCommentsTest360(test360Id)
        .then((response) => {
          context.commit(mutationTypes.getCommentsTest360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCommentsTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getApproversTest360](context, test360Id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getApproversTest360Start);
      testingApi
        .getApproversTest360(test360Id)
        .then((response) => {
          context.commit(mutationTypes.getApproversTest360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getApproversTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getDescriptionTest360](context, test360Id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDescriptionTest360Start);
      testingApi
        .getDescriptionTest360(test360Id)
        .then((response) => {
          context.commit(mutationTypes.getDescriptionTest360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDescriptionTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getQuestions](context, {userId, skillId, specId, typeId, blockId, testing360Id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getQuestionsStart);
      testingApi
        .getQuestions(userId, skillId, specId, typeId, blockId, testing360Id)
        .then((response) => {
          context.commit(mutationTypes.getQuestionsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getQuestionsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getMetadataPersonalTests](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMetadataPersonalTestsStart);
      testingApi
        .getMetadataPersonalTests(userId)
        .then((response) => {
          context.commit(mutationTypes.getMetadataPersonalTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getMetadataPersonalTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.getUrlPersonalTest](context, {userId, testId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUrlPersonalTestStart);
      testingApi
        .getUrlPersonalTest(userId, testId)
        .then((response) => {
          context.commit(mutationTypes.getUrlPersonalTestSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUrlPersonalTestFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getVariantsPersonalReport](context, {userId, sessionUid}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getVariantsPersonalReportStart);
      testingApi
        .getVariantsPersonalReport(userId, sessionUid)
        .then((response) => {
          context.commit(mutationTypes.getVariantsPersonalReportSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getVariantsPersonalReportFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.exportPersonalReport](context, {userId, sessionUid, variantId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportPersonalReportStart);
      testingApi
        .exportPersonalReport(userId, sessionUid, variantId)
        .then((response) => {
          context.commit(mutationTypes.exportPersonalReportSuccess);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportPersonalReportFailure, result.response.status);
          reject(result);
        });
    });
  },

  [actionsTypes.getUserSurveys](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserSurveysStart);
      testingApi
        .getUserSurveys()
        .then((response) => {
          context.commit(mutationTypes.getUserSurveysSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserSurveysFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getUserTests](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserTestsStart);
      testingApi
        .getUserSurveys(true)
        .then((response) => {
          context.commit(mutationTypes.getUserTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserTestsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getUserFeedbacks](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserFeedbacksStart);
      testingApi
        .getUserSurveys(false, 'feedback')
        .then((response) => {
          context.commit(mutationTypes.getUserFeedbacksSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserFeedbacksFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.saveAnswer](context, {userId, bpId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.saveAnswerStart);
      testingApi
        .saveAnswer(userId, bpId, data)
        .then((response) => {
          context.commit(mutationTypes.saveAnswerSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.saveAnswerFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.resetAnswers](context, {userId, specId, blockId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.resetAnswersStart);
      testingApi
        .resetAnswers(userId, specId, blockId)
        .then((response) => {
          context.commit(mutationTypes.resetAnswersSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.resetAnswersFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.saveResults](context, {userId, specId, blockId, data, completion}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.saveResultsStart);
      testingApi
        .saveResults(userId, specId, blockId, data, completion)
        .then((response) => {
          context.commit(mutationTypes.saveResultsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.saveResultsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createReport](context, {userId, specCode, skillType}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createReportStart);
      testingApi
        .createReport(userId, specCode, skillType)
        .then((response) => {
          context.commit(mutationTypes.createReportSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createReportFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.approveTest360](context, {test360Id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.approveTest360Start);
      testingApi
        .approveTest360(test360Id, data)
        .then((response) => {
          context.commit(mutationTypes.approveTest360Success);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.approveTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.setDescriptionTest360](context, {test360Id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.setDescriptionTest360Start);
      testingApi
        .setDescriptionTest360(test360Id, data)
        .then((response) => {
          context.commit(mutationTypes.setDescriptionTest360Success);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.setDescriptionTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
