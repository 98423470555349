export const gettersTypes = {
  freeLicenses: '[license] free licenses',
  freeLicenses360: '[license] free licenses 360',
  freeLicensesPersonal: '[license] free licenses personal',
};

const getters = {
  [gettersTypes.freeLicenses]: (state, getters, rootState) => {
    return (state.licenses || []).filter(
      (license) => license.is_free && license.user_id === rootState.auth.currentUser.user_id
    );
  },
  [gettersTypes.freeLicenses360]: (state, getters) => {
    return getters[gettersTypes.freeLicenses].filter(
      (license) => license.report_code === 'test360'
    );
  },
  [gettersTypes.freeLicensesPersonal]: (state, getters, rootState) => {
    let result = [];

    (rootState.company.enabledPersonalTests || []).forEach(({report_code, report_name}) => {
      result.push({
        report_code,
        report_name,
        count_licenses: getters[gettersTypes.freeLicenses].filter(
          (license) => license.report_code === report_code
        ),
      });
    });

    return result;
  },
};

export default getters;
